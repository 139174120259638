@font-face {
  font-family: "Proxima Nova";
  src: url(../assets/fonts/proxima-nova/ProximaNova-Regular.woff2)
      format("woff2"),
    url(../assets/fonts/proxima-nova/ProximaNova-Regular.woff) format("woff");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Proxima Nova";
  src: url(../assets/fonts/proxima-nova/ProximaNova-Semibold.woff2)
      format("woff2"),
    url(../assets/fonts/proxima-nova/ProximaNova-Semibold.woff) format("woff");
  font-weight: 600;
  font-style: normal;
}
